import React from 'react';
import { Icon } from '@iconify/react';
import goIcon from "@iconify/icons-logos/go";
import tsIcon from "@iconify/icons-logos/typescript-icon";
import { HighlightedTextIcon } from 'components/molecules';

const Text = () => (
  <>
    I’ve spent about three years on improving my skills in{' '}
    <span className="font-bold text-gray-800">software engineering</span> using{' '}
    <HighlightedTextIcon
      as="a"
      href="https://go.dev"
      className="mx-2"
      icon={<Icon icon={goIcon} />}
    >
      Golang
    </HighlightedTextIcon>{' '}
    and{' '}
    <HighlightedTextIcon
      as="a"
      href="https://www.typescriptlang.org/"
      className="mx-2"
      icon={<Icon icon={tsIcon} />}
    >
      TypeScript
    </HighlightedTextIcon>{' '}
    that I am now pretty confident in building apps using these languages:
  </>
);

const Separator = () => (
  <>
    and these technologies:
  </>
);

const skills = {
  text: <Text />,
  separator: <Separator />,
};

export default skills;
