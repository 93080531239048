import React from 'react';
import { Icon } from '@iconify/react';
import javaIcon from "@iconify/icons-logos/java";
import kotlinIcon from "@iconify/icons-logos/kotlin";
import pythonIcon from "@iconify/icons-logos/python";
import swiftIcon from "@iconify/icons-logos/swift";
import nodeIcon from "@iconify/icons-logos/nodejs-icon";
import goIcon from "@iconify/icons-logos/go";
import tsIcon from "@iconify/icons-logos/typescript-icon";


const languages = [
  <a href="https://go.dev" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={goIcon} />
    <span className="tooltiptext">Golang</span>
  </a>,
  <a href="https://www.typescriptlang.org/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={tsIcon} />
    <span className="tooltiptext">TypeScript</span>
  </a>,
  <a href="https://nodejs.org/en" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={nodeIcon} />
    <span className="tooltiptext">NodeJS</span>
  </a>,
  <a href="https://www.java.com/en/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={javaIcon} />
    <span className="tooltiptext">Java</span>
  </a>,
  <a href="https://kotlinlang.org/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={kotlinIcon} />
    <span className="tooltiptext">Kotlin</span>
  </a>,
  <a href="https://python.org/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={pythonIcon} />
    <span className="tooltiptext">Python</span>
  </a>,
  <a href="https://www.swift.org/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
    <Icon className="text-5xl" icon={swiftIcon} />
    <span className="tooltiptext">Swift</span>
  </a>,
];

export default languages;
