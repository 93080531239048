import { HeaderTextProps } from "components/organisms/Header/types";

const header: HeaderTextProps = {
  headerText: {
    greetings: "Halo",
    intro: "Saya",
    preTitle: "seorang",
  },
};

export default header;
