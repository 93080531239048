import { ProjectCardDataProps } from "components/molecules/ProjectCard/types";

const projects: ProjectCardDataProps[] = [
  {
    title: "Go Todo",
    description_id:
      "Proyek backend pertama saya yang menggunakan GO. Sebuah proyek sederhana, namun bisa membawa saya menjuarai DevCode Back-End Challenge.",
    description_en:
      "My first backend project using GO. A small project, but able to bring me the 1st place in DevCode Back-End Challenge.",
    type: "web",
    url: "https://github.com/farrelnajib/gotodo",
  },
  {
    title: "televote.me",
    description_id:
      "Sistem pemilihan umum yang mengedepankan integritas dan keamanan data. Aplikasi web ini dibuat menggunakan Laravel.",
    description_en:
      "An election system that prioritizes data integrity and security. This web app is built using Laravel.",
    type: "web",
    url: "https://televote.me/",
  },
  {
    title: "Foodecide",
    description_id:
      "Aplikasi iOS untuk membandingkan antara 2 makanan berdasarkan harga, ukuran, dan kandungan minyak di dalamnya.",
    description_en:
      "An iOS app to compare between 2 foods from the price, size, and oil content.",
    type: "ios",
    url: "https://farrelanshary.notion.site/Foodecide-Compare-your-food-to-decide-2a4c6d6039cd499f95a6ca1823a20e85",
  },
  {
    title: "Board It!",
    description_id:
      "Aplikasi iPad yang dibuat untuk semua yang ingin belajar cara membuat storyboard.",
    description_en:
      "An iPad app made for everyone who wants to learn how to make a storyboard.",
    type: "ios",
    url: "https://farrelanshary.notion.site/Board-It-Make-Storyboard-Easy-6db0f305530643349ce342d61a746573",
  },
  {
    title: "Emocean",
    description_id:
      "Aplikasi iOS untuk membantu kita mengenali diri lebih dalam dengan cara melakukan refleksi.",
    description_en:
      "An iOS app to let us know more about ourselves by doing reflection.",
    type: "ios",
    url: "https://farrelanshary.notion.site/EMOCEAN-Get-to-know-your-emotion-c00ae44d3a5043f0b4619b0463683f46",
  },
  {
    title: "Natics",
    description_id:
      "Aplikasi macOS yang dapat membantu investigator perdagangan hewan liar menginvestigasi kasus perdagangan ilegal.",
    description_en:
      "A macOS app to help Illegal Wildlife Trade investigators investigate IWT cases.",
    type: "ios",
    url: `${window.location.origin}/Natics.pdf`,
  },
];

export default projects;
