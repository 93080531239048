import { BlogsPostProps } from "components/molecules/BlogPost/types";

const blogs: BlogsPostProps[] = [
  {
    title: "Chain Fusion Hacker House at Coinfest Asia 3rd Winner",
    date: new Date("2024-08-23"),
    url: "https://www.linkedin.com/posts/farrelanshary_chain-fusion-hacker-house-winners-activity-7236374437908860928-WXIH"
  },
  {
    title: "Algobash Code Run: Backend Warriors 3rd Winner",
    date: new Date("2022-12-23"),
    url: "/certificates/Code-Run-2022.pdf"
  },
  {
    title: "IYCL Vol.2 Mekari x Money Forward Ranked 40, Silver Coders",
    date: new Date("2022-01-13"),
    url: "/certificates/IYCL-2022.pdf"
  },
  {
    title: "DevCode Back-End Challenge 1st Winner",
    date: new Date("2022-01-07"),
    url: "/certificates/Devcode-2022.pdf"
  },
  {
    title: "Ganesha IoTech Makersthon Best IoT Design Winner",
    date: new Date("2021-02-21"),
    url: "/certificates/Ganesha-IoTech-2021.pdf"
  },
  {
    title:
      "Widyatama International Academic Competition and Exhibition Programming Contest 1st Winner",
    date: new Date("2019-12-12"),
  },
  {
    title: "ICPC Asia Jakarta Regional Programming Contest Finalist",
    date: new Date("2019-10-27"),
  },
  {
    title: "ICPC Maranatha Provincial Programming Contest Finalist",
    date: new Date("2019-09-28"),
  },
];

export default blogs;
