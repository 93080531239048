import React from 'react';
import { HighlightedTextIcon } from 'components/molecules';
import { AboutSectionContentProps } from 'components/organisms/About/types';
import { Icon } from "@iconify/react";
import tsIcon from '@iconify/icons-logos/typescript-icon';
import goIcon from "@iconify/icons-logos/go";

const Text = () => (
  <>
    Saya adalah{' '}
    <span className="font-bold text-gray-800">Sarjana Komputer (S.Kom)</span>{' '}
    dari{' '}
    <span className="font-bold text-gray-800">Universitas Bina Nusantara</span>{' '}
    dengan spesialisasi di bidang <i>Software Engineering</i>. Saya sudah bekerja sebagai <i>Software Engineer</i> menggunakan{' '}
    <HighlightedTextIcon
      as="a"
      href="https://go.dev"
      className="mx-2"
      icon={<Icon icon={goIcon} />}
    >
      Golang
    </HighlightedTextIcon>{' '}
    dan{' '}
    <HighlightedTextIcon
      as="a"
      href="https://www.typescriptlang.org/"
      className="mx-2"
      icon={<Icon icon={tsIcon} />}
    >
      TypeScript
    </HighlightedTextIcon>{' '}
    selama lebih dari 3 tahun. Saat ini saya sedang bekerja sebagai <i>Software Engineer</i> di <strong><i>GovTech Procurement</i> (Telkom Indonesia)</strong>.
  </>
);

const about: AboutSectionContentProps = {
  text: <Text />,
};

export default about;
