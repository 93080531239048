import React from 'react';
import { Icon } from '@iconify/react';
import tsIcon from "@iconify/icons-logos/typescript-icon";
import goIcon from "@iconify/icons-logos/go";
import { HighlightedTextIcon } from 'components/molecules';

const Text = () => (
  <>
    Saya sudah berpengalaman selama sekitar tiga tahun mendalami{' '}
    <span className="font-bold text-gray-800">pengembangan <i>software</i></span> menggunakan{' '}
    <HighlightedTextIcon
      as="a"
      href="https://go.dev"
      className="mx-2"
      icon={<Icon icon={goIcon} />}
    >
      Golang
    </HighlightedTextIcon>{' '}
    dan{' '}
    <HighlightedTextIcon
      as="a"
      href="https://www.typescriptlang.org/"
      className="mx-2"
      icon={<Icon icon={tsIcon} />}
    >
      TypeScript
    </HighlightedTextIcon>{' '}
    hingga saya cukup percaya diri untuk membangun aplikasi menggunakan bahasa pemrograman:
  </>
);

const Separator = () => (
  <>
    dan teknologi:
  </>
);

const skills = {
  text: <Text />,
  separator: <Separator />,
};

export default skills;
