import React from 'react';
import { Icon } from '@iconify/react';
import rabbitMQIcon from "@iconify/icons-logos/rabbitmq";
import redisIcon from "@iconify/icons-logos/redis";
import elasticIcon from "@iconify/icons-logos/elasticsearch";
import azureIcon from "@iconify/icons-logos/azure-icon";
import gqlIcon from "@iconify/icons-logos/graphql";
import psqlIcon from "@iconify/icons-logos/postgresql";
import gCloudIcon from "@iconify/icons-logos/google-cloud";
import seleniumIcon from "@iconify/icons-logos/selenium";
import dockerIcon from "@iconify/icons-logos/docker-icon";

const tools = [
    <a href="https://cloud.google.com/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={gCloudIcon} />
      <span className="tooltiptext">Google Cloud</span>
    </a>,
    <a href="https://www.elastic.co/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={elasticIcon} />
      <span className="tooltiptext">ElasticSearch</span>
    </a>,
    <a href="https://www.rabbitmq.com/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={rabbitMQIcon} />
      <span className="tooltiptext">RabbitMQ</span>
    </a>,
    <a href="https://www.postgresql.org/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={psqlIcon} />
      <span className="tooltiptext">PostgreSQL</span>
    </a>,
    <a href="https://redis.io/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={redisIcon} />
      <span className="tooltiptext">Redis</span>
    </a>,
    <a href="https://graphql.org/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={gqlIcon} />
      <span className="tooltiptext">GraphQL</span>
    </a>,
    <a href="https://azure.microsoft.com/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={azureIcon} />
      <span className="tooltiptext">Azure</span>
    </a>,
    <a href="https://www.selenium.dev/" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={seleniumIcon} />
      <span className="tooltiptext">Selenium</span>
    </a>,
    <a href="https://www.docker.com//" className="focus:outline-none tooltip" target="_blank" rel="noreferrer">
      <Icon className="text-5xl" icon={dockerIcon} />
      <span className="tooltiptext">Docker</span>
    </a>,
];

export default tools