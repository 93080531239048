import { ContactSectionContentProps } from "components/organisms/Contact/types";

const contact: ContactSectionContentProps = {
  email: "me@farrelanshary.com",
  socials: {
    linkedin: "https://www.linkedin.com/in/farrelanshary/",
    whatsapp: "https://wa.me/62811232001",
    instagram: "https://instagram.com/farrelnajib",
  },
};

export default contact;
