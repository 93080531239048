import React from 'react';
import { Icon } from '@iconify/react';
import { HighlightedTextIcon } from 'components/molecules';
import { AboutSectionContentProps } from 'components/organisms/About/types';
import tsIcon from '@iconify/icons-logos/typescript-icon';
import goIcon from "@iconify/icons-logos/go";

const Text = () => (
  <>
    I am a{' '}
    <span className="font-bold text-gray-800">Bachelor of Computer Science</span>{' '}
    from{' '}
    <span className="font-bold text-gray-800">Binus University</span>{' '}
    with a specialization in Software Engineering. I have been working as a Software Engineer with using{' '}
    <HighlightedTextIcon
      as="a"
      href="https://go.dev"
      className="mx-2"
      icon={<Icon icon={goIcon} />}
    >
      Golang
    </HighlightedTextIcon>{' '}
    and{' '}
    <HighlightedTextIcon
      as="a"
      href="https://www.typescriptlang.org/"
      className="mx-2"
      icon={<Icon icon={tsIcon} />}
    >
      TypeScript
    </HighlightedTextIcon>{' '}
    for more than 3 years.
    Currently, I am working as a <i>Software Engineer</i> at <strong><i>GovTech Procurement</i> (Telkom Indonesia)</strong>.
  </>
);

const about: AboutSectionContentProps = {
  text: <Text />,
};

export default about;
