import { HeaderTextProps } from "components/organisms/Header/types";

const header: HeaderTextProps = {
  headerText: {
    greetings: "Hello",
    intro: "I'm",
    preTitle: "a",
  },
};

export default header;
